import React from "react";
const styles = {
  rtl: {
    direction: "rtl",
  } as React.CSSProperties,
  makeInline: {
    display: "flex !important",
  } as React.CSSProperties,
  loanContainer: {
    display: "flex",
    flexDirection: "column",
    direction: "rtl",
    justifyContent: "center",
  } as React.CSSProperties,
  fieldsetSchedulerBorder: {
    border: "2px solid #cccccc",
    //padding: "0 1em 1em 1em !important",
    margin: "0 0 1% 0 !important",
    boxShadow: "0 0 0 0 #000",
    padding: "2%",
  } as React.CSSProperties,
  legendSchedulerBorder: {
    fontSize: "120%",
    fontWeight: "bold",
    textAlign: "right",
    width: "fit-content",
    // padding:"0 1%",
    borderBottom: "none",
  } as React.CSSProperties,
  btnNext: {
    height: "fit-content",
    marginRight: "4%",
  } as React.CSSProperties,
  checkBox: {
    marginTop: "2%",
  } as React.CSSProperties,
};

export default styles;
