import React, { useState } from 'react';
import { Container, Button, Form, Row, Col, Modal } from 'react-bootstrap';
import { getLocaleWiseLabel as t } from '../../../lib/localeUtils';
import styles from '../UserDetailsPage/user-details-form.style';
import { navigate } from 'gatsby';
import clientConfig from '../../../../client-config';
import axios from 'axios';
import hebrewTexts from '../commonHebrewTexts';
interface Props {
  labelArr: string[];
}

const HOUSE_NUMBER_DEFAULT_MIN: number = 1;

interface IState {
  show: boolean;
  errorMessage: Array<string>;
  showOtherInputEntityType: boolean;
  showOtherInputFieldOfBusiness: boolean;
  entityType: string;
  companyName: string;
  businessPhone: string;
  businessEmail: string;
  creationYear: number;
  fieldOfBusiness: string;
  registrarCompaniesId: number;
  city: string;
  street: string;
  houseNumber: number;
  websiteLink: string;
  companyDetails: string;
  addCommercialAddressSection: boolean;
  commercialAddress_city: string;
  commercialAddress_street: string;
  commercialAddress_houseNumber: number;
  businessId: number;
  uid: string;
}
class BusinessDetailsForm extends React.Component<Props, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      show: false,
      errorMessage: [],
      showOtherInputEntityType: false,
      showOtherInputFieldOfBusiness: false,
      entityType: '',
      companyName: '',
      businessPhone: '',
      businessEmail: '',
      creationYear: new Date().getFullYear(),
      fieldOfBusiness: '',
      registrarCompaniesId: 0,
      city: '',
      street: '',
      houseNumber: HOUSE_NUMBER_DEFAULT_MIN,
      websiteLink: '',
      companyDetails: '',
      addCommercialAddressSection: false,
      commercialAddress_city: '',
      commercialAddress_street: '',
      commercialAddress_houseNumber: HOUSE_NUMBER_DEFAULT_MIN,
      businessId: 0,
      uid: '',
    };
  }

  handleClose = () => {
    this.setState({ show: false });
  };
  handleShow = () => {
    this.setState({ show: true });
  };

  validateMail(email: string) {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    return reg.test(email);
  }

  componentDidMount() {
    const companyName = localStorage.loanDetails ? JSON.parse(localStorage.loanDetails).companyName : '';
    const registrarCompaniesId = localStorage.loanDetails
      ? JSON.parse(localStorage.loanDetails).registeredCompanyId
      : 0;
    const businessId = localStorage.userDetails ? JSON.parse(localStorage.userDetails).businessId : 0;
    const uid = localStorage.userDetails ? JSON.parse(localStorage.userDetails).uid : '';
    this.setState({ companyName, registrarCompaniesId, businessId, uid });
  }
  submitHandler = async () => {
    let errorMessage = [];
    console.log('insdfsd', this.state);

    if (!this.state.entityType || this.state.entityType == 'other') {
      errorMessage.push(t(this.props.labelArr, 'entityType_Label', 'heb'));
    }

    if (!this.state.companyName) {
      errorMessage.push(t(this.props.labelArr, 'companyName_Label', 'heb'));
    }
    let phoneRegex =
      /^(?:(?:(\+?972|\(\+?972\)|\+?\(972\))(?:\s|\.|-)?([1-9]\d?))|(0[23489]{1})|(0[57]{1}[0-9]))(?:\s|\.|-)?([^0\D]{1}\d{2}(?:\s|\.|-)?\d{4})$/;
    if (!this.state.businessPhone.match(phoneRegex)) {
      errorMessage.push(t(this.props.labelArr, 'businessPhone_Label', 'heb'));
    }

    if (!this.state.businessEmail || !this.validateMail(this.state.businessEmail)) {
      errorMessage.push(t(this.props.labelArr, 'businessEmail_Label', 'heb'));
    }

    if (
      !this.state.creationYear ||
      !(1920 <= this.state.creationYear && this.state.creationYear <= new Date().getFullYear())
    ) {
      alert(this.state.creationYear + ' ' + new Date().getFullYear());
      errorMessage.push(t(this.props.labelArr, 'creationYear_Label', 'heb'));
    }

    if (!this.state.fieldOfBusiness || this.state.fieldOfBusiness == 'other') {
      errorMessage.push(t(this.props.labelArr, 'fieldOfBusiness_Label', 'heb'));
    }

    if (!this.state.city) {
      errorMessage.push(t(this.props.labelArr, 'city_Label', 'heb'));
    }
    if (!this.state.street) {
      errorMessage.push(t(this.props.labelArr, 'street_Label', 'heb'));
    }
    console.log(!this.state.houseNumber);
    if (this.state.houseNumber < HOUSE_NUMBER_DEFAULT_MIN || !this.state.houseNumber) {
      errorMessage.push(t(this.props.labelArr, 'houseNumber_Label', 'heb'));
    }

    // if (!this.state.websiteLink) {
    //   errorMessage.push(t(this.props.labelArr, "websiteLink_Label", "heb"));
    // }
    if (!this.state.companyDetails) {
      errorMessage.push(t(this.props.labelArr, 'companyDetails_Label', 'heb'));
    }
    if (this.state.addCommercialAddressSection) {
      if (!this.state.commercialAddress_city) {
        errorMessage.push(t(this.props.labelArr, 'city_Label', 'heb'));
      }
      if (!this.state.commercialAddress_street) {
        errorMessage.push(t(this.props.labelArr, 'street_Label', 'heb'));
      }
      if (
        this.state.commercialAddress_houseNumber < HOUSE_NUMBER_DEFAULT_MIN ||
        !this.state.commercialAddress_houseNumber
      ) {
        errorMessage.push(t(this.props.labelArr, 'houseNumber_Label', 'heb'));
      }
    }

    if (errorMessage.length > 0) {
      this.setState({ errorMessage: errorMessage });
      this.handleShow();
    } else {
      let businessDetails = {
        id: this.state.businessId,
        type: this.state.entityType,
        companyName: this.state.companyName,
        phone: this.state.businessPhone,
        email: this.state.businessEmail,
        registeredCompanyId: this.state.registrarCompaniesId,
        creationYear: this.state.creationYear,
        fieldOfBusiness: this.state.fieldOfBusiness,
        city: this.state.city,
        street: this.state.street,
        houseNumber: this.state.houseNumber,
        websiteLink: this.state.websiteLink,
        aboutTheCompany: this.state.companyDetails,
        commercialAddress_city: this.state.commercialAddress_city,
        commercialAddress_street: this.state.commercialAddress_street,
        commercialAddress_houseNumber: this.state.commercialAddress_houseNumber,
      };
      console.log('uid', this.state.uid);

      let currentPageUpdate = {
        uid: this.state.uid,
        currentPage: '/DisclaimerPage',
      };
      let req = {
        updateBusiness: businessDetails,
        updateCurrentPage: currentPageUpdate,
        bId: this.state.businessId,
      };

      axios
        .post(`${clientConfig.backend}/api/v1/updateBusiness`, req)
        .then((res) => {
          console.log('res ', res);
          navigate('/DisclaimerPage');
        })
        .catch((error) => {
          errorMessage.push(error.message);
        });
      navigate('/DisclaimerPage');
      console.log('businessDetails ', businessDetails);
    }
  };
  render() {
    const { labelArr } = this.props;
    const {
      show,
      errorMessage,
      showOtherInputEntityType,
      showOtherInputFieldOfBusiness,
      addCommercialAddressSection,
      entityType,
      companyName,
      businessPhone,
      businessEmail,
      creationYear,
      fieldOfBusiness,
      registrarCompaniesId,
      city,
      street,
      houseNumber,
      websiteLink,
      companyDetails,
      commercialAddress_city,
      commercialAddress_street,
      commercialAddress_houseNumber,
    } = this.state;
    return (
      <Container style={styles.loanContainer}>
        <Modal show={show} onHide={this.handleClose}>
          <Modal.Body style={{ fontSize: '100%' }} className='text-right'>
            {t(labelArr, 'FollowingRequiredFields', 'heb')} <br />
            {errorMessage.map((value) => {
              return (
                <h6 className='text-right' key={value}>
                  {value}
                </h6>
              );
            })}
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={this.handleClose}>
              {hebrewTexts.close}
            </Button>
          </Modal.Footer>
        </Modal>
        <h1 className='text-center' style={{ marginBottom: '5%' }}>
          {t(labelArr, 'businessPageDetails_Title', 'heb')}
        </h1>
        <Form.Group as={Row}>
          <Form.Label column className='text-right' sm='2'>
            {t(labelArr, 'entityType_Label', 'heb')}
          </Form.Label>
          <Col sm='4'>
            <Form.Control
              className='text-right'
              as='select'
              onChange={(e) => {
                let show = e.target.value === 'other' ? true : false;
                // setShowOtherInput(show);
                this.setState({ entityType: e.target.value, showOtherInputEntityType: show });
              }}
            >
              <option value=''>----בחר----</option>
              <option value={t(labelArr, 'limitedCompany', 'heb')}>{t(labelArr, 'limitedCompany', 'heb')}</option>
              <option value={t(labelArr, 'soleProprietor', 'heb')}>{t(labelArr, 'soleProprietor', 'heb')}</option>
              <option value={t(labelArr, 'partnership', 'heb')}>{t(labelArr, 'partnership', 'heb')}</option>
              <option value={t(labelArr, 'foundation', 'heb')}>{t(labelArr, 'foundation', 'heb')}</option>
              <option value={t(labelArr, 'publicBenefitCompany', 'heb')}>
                {t(labelArr, 'publicBenefitCompany', 'heb')}
              </option>
              <option value='other'>{t(labelArr, 'entityTypeOptions_other', 'heb')}</option>
            </Form.Control>
          </Col>
          <Col sm='4'>
            {showOtherInputEntityType ? (
              <Form.Control
                type='text'
                onChange={(e) => {
                  this.setState({ entityType: e.target.value });
                }}
              />
            ) : null}
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label className='text-right' column sm='2'>
            {t(labelArr, 'companyName_Label', 'heb')}
          </Form.Label>
          <Col sm='4'>
            <Form.Control
              min={0}
              onChange={(e) => {
                this.setState({ companyName: e.target.value });
              }}
              value={companyName ? companyName : ''}
              className='text-right'
              type='text'
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label className='text-right' column sm='2'>
            {t(labelArr, 'registrarCompaniesId_Label', 'heb')}
          </Form.Label>
          <Col sm='4'>
            <Form.Control
              // min={0}
              // onChange={(e)=>{this.setState({registrarCompaniesId:e.target.value})}}
              value={registrarCompaniesId ? registrarCompaniesId : ''}
              className='text-right'
              type='number'
              readOnly
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label className='text-right' column sm='2'>
            {t(labelArr, 'businessPhone_Label', 'heb')}
          </Form.Label>
          <Col sm='4'>
            <Form.Control
              min={0}
              onChange={(e) => {
                this.setState({ businessPhone: e.target.value });
              }}
              value={businessPhone ? businessPhone : ''}
              className='text-right'
              type='text'
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label className='text-right' column sm='2'>
            {t(labelArr, 'businessEmail_Label', 'heb')}
          </Form.Label>
          <Col sm='4'>
            <Form.Control
              min={0}
              onChange={(e) => {
                this.setState({ businessEmail: e.target.value });
              }}
              value={businessEmail ? businessEmail : ''}
              className='text-right'
              type='email'
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label className='text-right' column sm='2'>
            {t(labelArr, 'creationYear_Label', 'heb')}
          </Form.Label>
          <Col sm='4'>
            <Form.Control
              min='1920'
              max={new Date().getFullYear() + 1}
              onChange={(e) => {
                if (parseInt(e.target.value) <= new Date().getFullYear()) {
                  this.setState({ creationYear: parseInt(e.target.value) });
                }
              }}
              value={creationYear}
              className='text-right'
              type='number'
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column className='text-right' sm='2'>
            {t(labelArr, 'fieldOfBusiness_Label', 'heb')}
          </Form.Label>
          <Col sm='4'>
            <Form.Control
              className='text-right'
              as='select'
              onChange={(e) => {
                let show = e.target.value === 'other' ? true : false;
                // setShowOtherInput(show);
                this.setState({
                  fieldOfBusiness: e.target.value,
                  showOtherInputFieldOfBusiness: show,
                });
              }}
            >
              <option value=''>----בחר----</option>
              <option value={t(labelArr, 'miningAndQuarrying', 'heb')}>
                {t(labelArr, 'miningAndQuarrying', 'heb')}
              </option>
              <option value={t(labelArr, 'agricultureForestryAndFishing', 'heb')}>
                {t(labelArr, 'agricultureForestryAndFishing', 'heb')}
              </option>
              <option value={t(labelArr, 'industryAndFactory', 'heb')}>
                {t(labelArr, 'industryAndFactory', 'heb')}
              </option>
              <option value={t(labelArr, 'airConditioningElectricityGasAndEnergy', 'heb')}>
                {t(labelArr, 'airConditioningElectricityGasAndEnergy', 'heb')}
              </option>
              <option value={t(labelArr, 'building', 'heb')}>{t(labelArr, 'building', 'heb')}</option>
              <option value={t(labelArr, 'communication', 'heb')}>{t(labelArr, 'communication', 'heb')}</option>
              <option value={t(labelArr, 'transportationTransportLogistics', 'heb')}>
                {t(labelArr, 'transportationTransportLogistics', 'heb')}
              </option>
              <option value={t(labelArr, 'healthWelfareAndWelfareServices', 'heb')}>
                {t(labelArr, 'healthWelfareAndWelfareServices', 'heb')}
              </option>
              <option value={t(labelArr, 'accommodationAndDiningServices', 'heb')}>
                {t(labelArr, 'accommodationAndDiningServices', 'heb')}
              </option>
              <option value={t(labelArr, 'entertainmentAndRecreationcharter', 'heb')}>
                {t(labelArr, 'entertainmentAndRecreationcharter', 'heb')}
              </option>
              <option value={t(labelArr, 'informationAndCommunication', 'heb')}>
                {t(labelArr, 'informationAndCommunication', 'heb')}
              </option>
              <option value='other'>{t(labelArr, 'fieldOfbusiness_other', 'heb')}</option>
            </Form.Control>
          </Col>
          <Col sm='4'>
            {showOtherInputFieldOfBusiness ? (
              <Form.Control
                type='text'
                onChange={(e) => {
                  this.setState({ fieldOfBusiness: e.target.value });
                }}
              />
            ) : null}
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label className='text-right' column sm='2'>
            {t(labelArr, 'address_SectionTitle', 'heb')}
          </Form.Label>
          <Col md={9} className='w-20'>
            <fieldset style={styles.fieldsetSchedulerBorder}>
              <legend style={styles.legendSchedulerBorder}>{t(labelArr, 'address_SectionTitle', 'heb')}</legend>
              <Row>
                <Col sm='2'>
                  <Form.Label>{t(labelArr, 'city_Label', 'heb')}</Form.Label>
                </Col>
                <Col sm='8'>
                  <Form.Group>
                    <Form.Control
                      onChange={(e) => {
                        this.setState({ city: e.target.value });
                      }}
                      type='text'
                      required
                      value={city ? city : ''}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm='2'>
                  <Form.Label>{t(labelArr, 'street_Label', 'heb')}</Form.Label>
                </Col>
                <Col sm='4'>
                  <Form.Group>
                    <Form.Control
                      onChange={(e) => {
                        this.setState({ street: e.target.value });
                      }}
                      type='text'
                      required
                      value={street ? street : ''}
                    />
                  </Form.Group>
                </Col>

                <Col sm='2'>
                  <Form.Label>{t(labelArr, 'houseNumber_Label', 'heb')}</Form.Label>
                </Col>
                <Col sm='2'>
                  <Form.Group>
                    <Form.Control
                      onChange={(e) => {
                        this.setState({ houseNumber: parseInt(e.target.value) });
                      }}
                      type='number'
                      value={houseNumber ? houseNumber : HOUSE_NUMBER_DEFAULT_MIN.toString()}
                      min={HOUSE_NUMBER_DEFAULT_MIN.toString()}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
            </fieldset>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label className='text-right' column sm='2'>
            {t(labelArr, 'isExsitCommercialAddress_Label', 'heb')}
          </Form.Label>
          <Row>
            <Col sm='4'>
              <Form.Group style={{ display: 'flex' }}>
                <Form.Label>{t(labelArr, 'yesRadioBtn', 'heb')}</Form.Label>
                <Form.Check
                  onChange={() => {
                    this.setState({ addCommercialAddressSection: true });
                  }}
                  type='radio'
                  name='commercialAddressSectionRadios'
                />
                <Form.Label className='mr-3'>{t(labelArr, 'noRadioBtn', 'heb')}</Form.Label>
                <Form.Check
                  onChange={() => {
                    this.setState({ addCommercialAddressSection: false });
                  }}
                  defaultChecked
                  type='radio'
                  name='commercialAddressSectionRadios'
                />
              </Form.Group>
            </Col>
          </Row>
        </Form.Group>
        {addCommercialAddressSection ? (
          <Form.Group as={Row}>
            <Form.Label className='text-right' column sm='2'>
              {t(labelArr, 'address_SectionTitle', 'heb')}
            </Form.Label>
            <Col md={9} className='w-20'>
              <fieldset style={styles.fieldsetSchedulerBorder}>
                <legend style={styles.legendSchedulerBorder}>{t(labelArr, 'address_SectionTitle', 'heb')}</legend>
                <Row>
                  <Col sm='2'>
                    <Form.Label>{t(labelArr, 'city_Label', 'heb')}</Form.Label>
                  </Col>
                  <Col sm='8'>
                    <Form.Group>
                      <Form.Control
                        onChange={(e) => {
                          this.setState({ commercialAddress_city: e.target.value });
                        }}
                        type='text'
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm='2'>
                    <Form.Label>{t(labelArr, 'street_Label', 'heb')}</Form.Label>
                  </Col>
                  <Col sm='4'>
                    <Form.Group>
                      <Form.Control
                        onChange={(e) => {
                          this.setState({ commercialAddress_street: e.target.value });
                        }}
                        type='text'
                        required
                      />
                    </Form.Group>
                  </Col>

                  <Col sm='2'>
                    <Form.Label>{t(labelArr, 'houseNumber_Label', 'heb')}</Form.Label>
                  </Col>
                  <Col sm='2'>
                    <Form.Group>
                      <Form.Control
                        onChange={(e) => {
                          this.setState({
                            commercialAddress_houseNumber: parseInt(e.target.value),
                          });
                        }}
                        type='number'
                        defaultValue={HOUSE_NUMBER_DEFAULT_MIN.toString()}
                        min={HOUSE_NUMBER_DEFAULT_MIN.toString()}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </fieldset>
            </Col>
          </Form.Group>
        ) : (
          <></>
        )}
        <Form.Group as={Row}>
          <Form.Label className='text-right' column sm='2'>
            {t(labelArr, 'websiteLink_Label', 'heb')}
          </Form.Label>
          <Col sm='4'>
            <Form.Control
              min={0}
              onChange={(e) => {
                this.setState({ websiteLink: e.target.value });
              }}
              value={websiteLink ? websiteLink : ''}
              className='text-right'
              type='text'
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label className='text-right' column sm='2'>
            {t(labelArr, 'companyDetails_Label', 'heb')}
          </Form.Label>
          <Col sm='4'>
            <Form.Control
              onChange={(e) => {
                this.setState({ companyDetails: e.target.value });
              }}
              value={companyDetails ? companyDetails : ''}
              className='text-right'
              as='textarea'
              rows={3}
            />
          </Col>
        </Form.Group>
        <Button
          onClick={this.submitHandler}
          style={{ alignSelf: 'center', marginTop: '50px' }}
          variant='primary'
          type='submit'
        >
          {t(labelArr, 'continue_Button', 'heb') + ' >'}
        </Button>
      </Container>
    );
  }
}

export default BusinessDetailsForm;
